import {Alert, Snackbar, Slide} from "@mui/material";
import CryptoJS from 'crypto-js';
import {PaginationInit} from '../utils/InitValues';
import useAuth from "../hooks/useAuth";
import {Fragment} from "react";

export function BasePathAcess() {
    //TUDO EM LETRA PEQUENA
    return ["/auth/dashboard"];
}


export function SnackMessage(info, state, severity, autoHideDuration = 3500, vertical = 'bottom', horizontal='right') {
    const allowedSeverities = ["error", "success", "info", "warning"];
    severity = allowedSeverities.includes(severity) ? severity : "info";
    if (!info) return null;
    let messageContent = info?.message || (info?.errors && MessageError(info?.errors)) || info;
    if (messageContent?.endsWith('\n') ?? false) {
        messageContent = messageContent.slice(0, -1);
    }
    messageContent = messageContent.split('\n').map((line, index) => (
        <Fragment key={index}>
            {line}
            <br/>
        </Fragment>
    ));

    return (
        <Snackbar open={true} autoHideDuration={autoHideDuration} onClose={state} TransitionComponent={TransitionLeft}
                  anchorOrigin={{vertical, horizontal}}>
            <Alert severity={severity} sx={{width: "100%"}} variant="filled">
                {messageContent}
            </Alert>
        </Snackbar>
    );
}


export function MessageError(errors) {
    if (!errors) return null;

    var text = "";
    Object.keys(errors).forEach((fieldName) => {
        errors[fieldName].forEach((error) => {
            text += "• " + fieldName + ": " + error + "\n";
        });
    });
    return text;
}


function TransitionLeft(props) {
    return <Slide {...props} direction="left"/>;
}

export function Decrypt(encryptedText) {

    if (encryptedText === null)
        return null;

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedText, process.env.REACT_APP_AES_KEY);
        const accessRoles = bytes.toString(CryptoJS.enc.Utf8);
        if (accessRoles) {
            return JSON.parse(accessRoles);
        } else {
            return null;
        }
    } catch (ex) {
        return null;
    }
}

export function Encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_AES_KEY).toString()
}

export function LoadLocalStorage(name) {
    return Decrypt(window.localStorage.getItem(name)) || null;
}

export function SaveLocalStorage(name, item) {
    if (item !== null) {
        localStorage.setItem(name, Encrypt(item));
    } else {
        localStorage.removeItem(name);
    }
}

export function CheckRolesRoutes() {
    let {isAuthenticated, user} = useAuth();
    if (isAuthenticated === true && user !== null) {
        if (user?.accountRoles?.some(item => item?.role === "SA")) {
            return {
                create: true,
                read: true,
                update: true,
                delete: true
            };
        }
    }

    const currentPath = window.location.pathname;
    if (currentPath) {
        const acessRoles = LoadLocalStorage("accessRoles");
        const userRoles = LoadLocalStorage("userRoles");

        if (acessRoles && userRoles) {
            const result = acessRoles.find(x => x?.path === currentPath);

            if (result) {
                const mergedRoles = {
                    create: false,
                    read: false,
                    update: false,
                    delete: false
                };

                result.roles.forEach(role => {
                    mergedRoles.create = mergedRoles.create || role.create;
                    mergedRoles.read = mergedRoles.read || role.read;
                    mergedRoles.update = mergedRoles.update || role.update;
                    mergedRoles.delete = mergedRoles.delete || role.delete;
                });

                return mergedRoles;
            }
        }
    }

    return null;

}


export function CheckPagination(response) {
    var pagination = (response?.headers["x-pagination"] !== undefined ? JSON.parse(response.headers["x-pagination"]) : PaginationInit(-2));
    return pagination
}

export function CheckPaginationMaterial(response) {
    var pagination = (response?.headers["x-pagination"] !== undefined ? JSON.parse(response.headers["x-pagination"]) : PaginationInit(-2));
    return {
        pageIndex: pagination?.Page || 0,
        pageSize: pagination?.RowsPerPage || 10,
        totalCount: pagination?.TotalCount || 0
    }
}

export const formatDateTimeLocal = (date) => {
    if (!date) return "";
    // Format date to 'YYYY-MM-DDTHH:MM' without time zone conversion
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const formatDateTime = (date) => {
    if (!date) return "";
    // Format date to 'YYYY-MM-DDTHH:MM' without time zone conversion
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export function FormatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const FormatTimeToHHmm = (timeString) => {
    const date = new Date(`1970-01-01T${timeString}Z`);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const FormatTime = (time) => {
    const date = new Date(time);
    return date.toTimeString().split(' ')[0];
};

export const convertFirstLetterToLowerCase = (str) => {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
};

export const isValidTime = (time) => {
    if (!time) return false;
    const date = new Date(time);
    return !isNaN(date.getHours()) && !isNaN(date.getMinutes());
};

export function OpenBase64NewTab(base64Pdf) {
    var blob = base64toBlob(base64Pdf);
    if (window.navigator && window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
    } else {
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);
    }
}

export function base64toBlob(base64Data) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: "application/pdf"});
}

export const tryParseJson = (data) => {
    try {
        return JSON.parse(data);
    } catch (e) {
        return null;
    }
}

export const tryParseFloat = (value) => {
    const parsedValue = parseFloat(value);
    return isNaN(parsedValue) ? null : parsedValue;
}

export function convertToDateOnlyString(currentMonth) {
    const regex = /^\d{4}-\d{2}$/;
    if (!regex.test(currentMonth)) {
        throw new Error('Invalid format. Expected "YYYY-MM".');
    }
    return `${currentMonth}-01`;
}

export const statusData = [
    {id: 1, status: "Pendente"},
    {id: 2, status: "Aceite"},
    {id: 3, status: "Recusado"},
    {id: 4, status: "Processado"}
];

export const weekDaysData = [
    {label: 'Domingo', weekDay: 0},
    {label: 'Segunda-Feira', weekDay: 1},
    {label: 'Terça-Feira', weekDay: 2},
    {label: 'Quarta-Feira', weekDay: 3},
    {label: 'Quinta-Feira', weekDay: 4},
    {label: 'Sexta-Feira', weekDay: 5},
    {label: 'Sábado', weekDay: 6}
];

export const dataTypes = [
    'text',
    'number',
    'boolean',
    'date',
    'datetime',
    'image',
    'excel'
]

export const valuesTypes = [
    {label: 'text'},
    {label: 'number'},
    {label: 'boolean'},
    {label: 'date'},
    {label: 'datetime'},
    {label: 'email'},
    {label: 'checkbox'},
];